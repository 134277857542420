<template>
  <validation-observer ref="project-profile-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col cols="12">
          <validation-provider
            v-slot="validationContext"
            name="Fecha de contratación"
            rules="required"
          >
            <b-form-group
              label="Fecha de contratación"
              label-for="hire-date"
            >
              <b-form-datepicker
                v-model="model.date_of_hiring"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                size="sm"
                reset-button
                :hide-header="true"
                placeholder="Fecha de contratación"
                :label-help="null"
                :state="$getValidationState(validationContext)"
                @input="update('date_of_hiring', $event)"
                start-weekday="1"
              />
            </b-form-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            v-slot="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="name"
            >
              <b-form-input
                :value="model.name"
                name="name"
                placeholder="Nombre"
                :state="$getValidationState(validationContext)"
                @input="update('name', $event)"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <validation-provider
            v-slot="validationContext"
            name="Dirección"
            rules="required"
          >
            <b-form-group
              label="Dirección"
              label-for="address"
            >
              <b-form-input
                :value="model.address"
                name="address"
                placeholder="Dirección"
                :state="$getValidationState(validationContext)"
                @input="update('address', $event)"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            v-slot="validationContext"
            name="País"
            rules="required"
          >
            <b-form-group
              label="País"
              label-for="project-country"
            >
              <BaseSelect
                id="project-country"
                :value="model.country"
                :resource="$data.$constants.RESOURCES.RESOURCE_COUNTRIES"
                http-method="get"
                @input="handleCountryInput"
              />
            </b-form-group>
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            v-slot="validationContext"
            name="Provincia"
            rules="required"
          >
            <b-form-group
              label="Provincia"
              label-for="project-province"
            >
              <BaseSelect
                id="project-province"
                ref="project-province-select"
                :value="model.province"
                :resource="$data.$constants.RESOURCES.RESOURCE_PROVINCES"
                @input="update('province', $event)"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            v-slot="validationContext"
            name="Población"
            rules="required"
          >
            <b-form-group
              label="Población"
              label-for="city"
            >
              <b-form-input
                :value="model.population"
                name="city"
                placeholder="Población"
                :state="$getValidationState(validationContext)"
                @input="update('population', $event)"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="6">
          <validation-provider
            v-slot="validationContext"
            name="Código postal"
            rules="required"
          >
            <b-form-group
              label="Código postal"
              label-for="postalCode"
            >
              <b-form-input
                :value="model.post_code"
                name="postalCode"
                placeholder="Código postal"
                :state="$getValidationState(validationContext)"
                @input="update('post_code', $event)"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col sm="12">
          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <b-form-textarea
              :value="model.observations"
              name="description"
              placeholder="Descripción"
              rows="4"
              max-rows="4"
              @input="update('observations', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12">
          <b-form-group label="Adjuntar Archivos">
            <FileDropZone
              ref="documentation-input"
              param-name="documentation"
              :files="files"
              @removed-file="removedFiles.push($event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'
import FileDropZone from '@/components/ui/file/FileDropZone.vue'
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'ProjectProfileForm',
  components: {
    BaseSelect,
    FileDropZone,
  },
  mixins: [FormMixin],
  data() {
    return {
      removedFiles: [],
    }
  },
  computed: {
    files() {
      return this.model.documentation
    },
  },
  methods: {
    validateForm() {
      return this.$refs['project-profile-form'].validate()
    },
    getDocumentationData() {
      const documentation = this.$refs['documentation-input'].getFiles()
      return { documentation, removedFiles: this.removedFiles }
    },
    handleCountryInput(country) {
      this.update('country', country)
      this.$refs['project-province-select'].loadData({ country })
    },
  },
}
</script>

<style scoped>
</style>
