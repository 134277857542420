<template>
  <BaseCard
    title="datos de la oferta"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <OfferForm
      ref="offer-form"
      v-model="offer"
    />
  </BaseCard>
</template>

<script>
import OffersApi from '@/api/offers-api'
import OfferForm from '@/components/offers/form/OfferForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, OfferForm },
  data() {
    return {
      loading: false,
      offer: {},
    }
  },
  mounted() {
    this.loadOffer()
  },
  methods: {
    async loadOffer() {
      try {
        this.loading = true
        const response = await OffersApi.get(this.$route.params.id)
        this.offer = response.data
      } finally {
        this.loading = false
      }
    },
    async handleSaveButtonClick() {
      const valid = await this.$refs['offer-form'].validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        const { documentation, removedFiles } = this.$refs['offer-form'].getDocumentationData()
        const offer = { ...this.offer, documentation }
        delete offer.lines_grouped
        await Promise.all([
          OffersApi.edit(this.$route.params.id, offer, false),
          OffersApi.deleteFiles(this.$route.params.id, removedFiles),
        ])
        this.$router.go(-1)
      } catch (error) {
        this.handleError(error)
      }

      this.loading = false
    },
    handleError(error) {
      if (error.response.data?.code) {
        this.$refs['offer-form'].focusCodeError()
        this.$toast.error(error.response.data.code[0])
      } else {
        this.$toast.error('Ocurrio un error al actualizar la oferta. Por favor inténtelo de nuevo mas tarde.')
      }
    },
  },
}
</script>

<style scoped>
</style>
