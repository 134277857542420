<template>
  <validation-observer ref="project-responsible-form">
    <b-form
      class="p-2"
      @submit.prevent
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Nombre"
            label-for="name"
          >
            <b-form-input
              :value="model.resp_name"
              name="name"
              placeholder="Nombre"
              @input="update('resp_name', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Cargo"
            label-for="job"
          >
            <b-form-input
              :value="model.resp_position"
              name="job"
              placeholder="Puesto"
              @input="update('resp_position', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Correo electrónico"
            label-for="email"
          >
            <b-form-input
              :value="model.resp_email"
              name="email"
              placeholder="Correo electrónico"
              @input="update('resp_email', $event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Teléfono"
            label-for="phone"
          >
            <b-form-input
              :value="model.resp_phone"
              name="phone"
              placeholder="Télefono"
              @input="update('resp_phone', $event)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import FormMixin from '@/mixins/formMixin'

export default {
  name: 'ProjectResponsibleForm',
  mixins: [FormMixin],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    validateForm() {
      return this.$refs['project-responsible-form'].validate()
    },
  },
}
</script>

<style scoped>
</style>
